import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { PaymentMethodThreshold, PaymentMethodWebsite, ThresholdOperationType, VerificationType } from './models/purchase-flow/paymentMethodWebsite';
import { SessionService } from './session-service';
import { IpAddressService } from './ip-address-service';
import { Helper } from 'resources/extensions/helper';
import { CurrencyService } from './currency-service';

@autoinject()
export class PaymentMethodWebsiteService {
    path = 'PaymentMethodWebsite';
    paymentMethods;
    payoutablePaymentMethods;
    thresholds: PaymentMethodThreshold[];

    constructor(private api: ApiService, private sessionService: SessionService, private ipAddressService: IpAddressService, private helper: Helper, private currencyService: CurrencyService) { }

    async filter(filter) {
        return await this.api.doGet(this.path + '?filter=' + filter);
    }

    async getByWebsite(payoutable = null): Promise<PaymentMethodWebsite[]> {
        if (payoutable) {
            if (this.payoutablePaymentMethods) return this.payoutablePaymentMethods;
            this.payoutablePaymentMethods = await this.api.doGet(`${this.path}/ByWebsite`, { websiteShortCode: websiteShortCode(), countryShortCode: await this.getCountryShortCode(), includePayoutable: true });
            return this.payoutablePaymentMethods;
        } else {
            if (this.paymentMethods) return this.paymentMethods;
            this.paymentMethods = await this.api.doGet(`${this.path}/ByWebsite`, { websiteShortCode: websiteShortCode(), countryShortCode: await this.getCountryShortCode() });
            return this.paymentMethods;
        }
    }

    async getRecentPaymentMethodByUserId() {
        return await this.api.doGet(this.path + `/RecentPaymentMethodByUserId?countryShortCode=${await this.getCountryShortCode()}`);
    }

    async getThresholds(): Promise<PaymentMethodThreshold[]> {
        return this.helper.handlePendingRequest(this, async() => {
            if (this.thresholds) return this.thresholds;
            this.thresholds = await this.api.doPost(this.path + '/GetMultipleThresholdsByIds', {
                websiteShortCode: websiteShortCode(),
                countryShortCode: await this.getCountryShortCode()
            });
            return this.thresholds;
        });
    }

    async getCountryShortCode(): Promise<string> {
        let countryShortCode = await this.sessionService.getCountry();
        if (!countryShortCode) countryShortCode = (await this.ipAddressService.getIpData())?.countryCode;
        return countryShortCode;
    }

    async getTotalCountOrderCompletedByPaymentMethod(userId: number, paymentMethodId: number) {
        return await this.api.doGet(`User/GetTotalCountOrderCompletedByPaymentMethod/${userId}/${paymentMethodId}`);
    }

    async getVerificationThresholds(totalSpentByUser, selectedPaymentMethod, totalPrice) {
        if (!this.thresholds) await this.getThresholds();
        const totalCountOrderCompleted = totalSpentByUser?.id && selectedPaymentMethod?.paymentMethodId ? await this.getTotalCountOrderCompletedByPaymentMethod(totalSpentByUser.id, selectedPaymentMethod.paymentMethodId) : 0;
        const frequencyToAmountMap = {
            0: totalSpentByUser?.totalSpent,
            1: totalSpentByUser?.total1DaySpent,
            7: totalSpentByUser?.total7DaySpent,
            30: totalSpentByUser?.total30DaySpent
        };
        const result = {
            amountRequiresVeriff: false,
            amountRequiresPhoneVeriff: false
        };

        for (const t of this.thresholds) {
            if (t.paymentMethodId !== selectedPaymentMethod?.paymentMethodId) continue;
            const amountSpent = frequencyToAmountMap[t.frequency] || 0;
            const currencyCode = (await this.currencyService.getCurrencyById(t.currencyId))?.code;
            const rate = this.currencyService.getStoredCurrencyRates(currencyCode);
            const requireVerif = (amountSpent + (totalPrice / rate)) >= t.threshold && totalCountOrderCompleted >= t.transactionThreshold;
            if (t.operationTypeList.includes(ThresholdOperationType.Sell)) {
                t.verificationTypeList.forEach(v => {
                    switch (v) {
                        case VerificationType.ID: result.amountRequiresVeriff = requireVerif; break;
                        case VerificationType.Phone: result.amountRequiresPhoneVeriff = requireVerif; break;
                    }
                });
            }
        }
        return result;
    }
}
