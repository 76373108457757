import './order-details.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { OrderService } from 'services/order-service';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { InvoiceService } from 'services/invoice-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { apiEndpoint, chicksXBaseUrl } from 'environment';
import { getAWSBucketEndpoint } from 'environment';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/extensions/helper';
import moment from 'moment';
import QRCode from 'qrcode';
import { Order } from 'services/models/order';
import { automaticPaymentMethods, automaticPaymentMethodsWithout } from 'resources/constants';

@autoinject()
export class Orders {
    baseAwsEndpoint;
    environment = apiEndpoint();
    order: Order;
    detailsElement;
    generatingPdf = false;
    card;
    cardWidth = 32;
    newBalanceAmount;
    user;
    qrCode;
    blocked;
    requestedAddressUpdate = false;
    showSupportMessage = false;
    userVeriffData;
    veriffSubscriber;
    addressVerification;
    veriffViewModel;
    dsVeriff;
    contactUsPageRoute;
    automaticPaymentMethods = automaticPaymentMethods();
    automaticPaymentMethodsWithout = automaticPaymentMethodsWithout();
    hasTwoFulfilledOrders = false;
    hasCXFulfilledOrders = false;
    is90DaysOrBannerSpecificDates = false;

    constructor(private router: Router,
        private orderService: OrderService,
        private sessionService: SessionService,
        private notification: ToastService,
        private invoiceService: InvoiceService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService,
        private customerService: CustomerService,
        private websiteService: WebsiteService,
        private helper: Helper
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('payment-methods');
    }

    async activate(params) {
        let pages;
        [this.order, pages] = await Promise.all([
            this.orderService.getById(params.id),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Cart')?.id);
        this.contactUsPageRoute = pages.find(x => x.name === 'Contact Us')?.routeName ?? 'contact';
        this.blocked = params.blocked;
        this.order.products.length && (this.order.insuranceFee = this.order.products?.map(x => x.insuranceFee).reduce((prev, next) => prev + next));
        if (this.order.balanceAmount > 0) {
            if (this.order.currencyUsed !== 'USD') {
                this.newBalanceAmount = this.order.balanceAmount * this.order.currencyRateUsed;
            } else {
                this.newBalanceAmount = this.order.balanceAmount;
            }
            this.order.convertedCurrencyTotal -= this.newBalanceAmount;
        }
        this.user = await this.sessionService.refreshProfile();

        if (!this.order) {
            this.router.navigate('');
        }

        await this.getItemTotals();
        if (this.order.paymentMethod.reference === 'alipay') {
            await this.generateQrCode();
        }

        if (params.STATUS && this.order.paymentMethod.reference === 'ideal') {
            if (this.order.status === 'ideal:pending') {
                await this.orderService.updateIdealStatus(this.order.id);
            }
        } else if (params.STATUS && this.order.paymentMethod.reference === 'sofort') {
            if (this.order.status === 'sofort:pending') {
                await this.orderService.updateSofortStatus(this.order.id);
            }
        }

        if (this.order.paymentMethod.reference.includes('skrill-direct')) {
            if (params.status === 'rejected') {
                await this.orderService.updateSkrillDirectStatus(this.order.id, params.status);
            }
            if (params.transaction_id?.includes(this.order.id)) {
                await this.orderService.updateSkrillDirectStatus(this.order.id, 'pending');
            }
        }

        if (this.helper.includesSome(this.order.paymentMethod.reference, ['checkout', 'google-pay-direct', 'apple-pay-direct']) && (params.status || params['cko-session-id'])) {
            const sessionId = params['cko-session-id'];
            if (sessionId && params.status !== 'rejected') {
                await this.orderService.updateCheckoutStatus(this.order.id, 'complete');
            } else if (params.status === 'rejected') {
                await this.orderService.updateCheckoutStatus(this.order.id, 'rejected');
            }
        }

        if (this.helper.includesSome(this.order.paymentMethod.reference, ['solidgate']) && params['order_id'] && params['status']) {
            await this.orderService.updateOrderStatus(this.order.id, params['status']);
        }
    }

    async attached() {
        this.updateNavBarBalance();
        this.sessionService.destroyCart();
        this.sessionService.destroyReferralLink();
        this.openLivechat();
        this.hasTwoFulfilledOrders = await this.orderService.checkIfUserHasFulfilledOrders(2, false);
        this.hasCXFulfilledOrders = await this.orderService.checkIfUserHasFulfilledOrders(1, false, 'CX');
        this.is90DaysOrBannerSpecificDates = !this.user.closedChicksXBannerDate || moment().isAfter(moment(this.user.closedChicksXBannerDate).add(90, 'days')) || this.helper.checkIfBannerHavePassedSpecificDates(this.user.closedChicksXBannerDate);
        if (this.hasTwoFulfilledOrders && this.is90DaysOrBannerSpecificDates && !this.hasCXFulfilledOrders) {
            this.eventAggregator.publish('banner-updated', { stateBanner: 'info', text: 'Did you know that having a verified profile on divicasales.com also means you already have a verified profile on chicksx.com?', url: chicksXBaseUrl(), clickFunctionName: 'visitChicksX' });
        }
        if (!this.user.notUsingTemporaryPassword && (!this.user.closedTempPasswordBanner || this.helper.checkIfBannerHavePassedSpecificDates(this.user.closedTempPasswordBannerDate))) {
            this.eventAggregator.publish('banner-updated', { successful: 'info', text: 'We\'ve noticed you haven\'t changed your temporary password yet. To safeguard your account, please', clickFunctionName: 'resetPassword' });
        }
        if (this.blocked) {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: this.order.hostedUrl });
        }
    }

    async getItemTotals() {
        this.order.subtotal = 0;

        for (const product of this.order.products) {
            product.price = (product.price / product.quantity);
            this.order.subtotal += product.totalPrice - product.totalFees;
        }

        for (const service of this.order.services) {
            service.price = (service.price / service.quantity);
            this.order.subtotal += service.totalPrice;
        }
    }

    backToOrders() {
        this.router.navigate('orders');
    }

    getSubtitle() {
        return `DS_SUBTITLE_${this.order.products[0].product.game.shortName}`;
    }

    openCheckoutWindow(url) {
        if (url) {
            window.open(url);
        }
    }

    openLivechat() {
        if (window.Intercom) {
            window.Intercom('showNewMessage', 'Hello! I have a question about order #' + this.order?.id);
        }
    }

    async resendReceipt() {
        const response = await this.orderService.resendReceipt(this.order.id);
        if (response) {
            return this.notification.showToast('Done!', 'A copy of your receipt has been sent to you.', 'success');
        } else {
            return this.notification.showToast('Error', 'Failed to send copy of receipt. Contact Livechat for assistance.', 'error');
        }
    }

    checkProductImageSource(orderProduct) {
        return orderProduct.product.productCategory?.name === 'Currency' || orderProduct.product.imagePath ? true : false;
    }

    getProductImageSource(orderProduct) {
        switch (orderProduct.product.productCategory.name) {
            case 'Currency':
                if (orderProduct.product.imagePath) {
                    return `${this.environment}Image/chicks-products/${orderProduct.product.imagePath}`;
                } else if (['WOWC', 'WOWWOTLK'].some(x => x.includes(orderProduct.product.game.shortName))) {
                    return '/icons/wow-classic-currency-icon.svg';
                } else if (orderProduct.product.game.shortName === 'WOWTBC') {
                    return '/icons/wow-classic-tbc-currency-icon.svg';
                } else if (orderProduct.product.game.shortName === 'WOWSOM') {
                    return '/icons/wow-classic-som-currency-icon.svg';
                } else if (orderProduct.product.game.shortName === 'WOW') {
                    return '/icons/wow-retail-currency-icon.svg';
                } else {
                    return '/icons/coins.png';
                }
            default:
                return `${this.environment}Image/chicks-products/${orderProduct.product.imagePath}`;
        }
    }

    getOrderProductStatusClass(orderProduct) {
        if (orderProduct.fulfilledAmount >= (orderProduct?.toFulfillAmount ?? orderProduct.quantity)) {
            return 'text-green';
        } else if (this.getOrderPaymentVerified()) {
            return 'text-yellow';
        } else {
            return 'text-red';
        }
    }

    getOrderPaymentVerified() {
        if (this.order.paymentMethodId) {
            return true;
        }
    }

    getOrderProductStatus(orderProduct) {
        if (orderProduct.fulfilledAmount >= (orderProduct?.toFulfillAmount ?? orderProduct.quantity)) {
            return 'Complete';
        } else if (this.getOrderPaymentVerified()) {
            return 'Pending';
        } else {
            return 'Incomplete';
        }
    }

    longProductNameCheck(orderProduct) {
        if (orderProduct.product.productCategoryId === 3) {
            if (orderProduct.product.name?.length > 24) {
                return true;
            } else {
                return false;
            }
        } else {
            if (orderProduct.product.productCategoryId === 5) {
                if ((orderProduct.serviceFullName.length) > 35) {
                    return true;
                } else {
                    return false;
                }
            } else if (orderProduct.product.name?.length > 35) {
                return true;
            } else {
                return false;
            }
        }
    }

    longCharacterNameCheck(characterName) {
        if (characterName?.length > 13) {
            return true;
        } else {
            return false;
        }
    }

    async handleExportingPdf() {
        if (!this.generatingPdf) {
            this.generatingPdf = true;
            try {
                const result = await this.invoiceService.generatePdfReceipt(this.order.id);
                if (result) {
                    const linkElement = document.createElement('a');
                    linkElement.href = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
                    linkElement.download = `ds_order_${this.order.id}_${moment.utc().local().format('MM/DD/YYYY hh:mm a')}.pdf`;
                    linkElement.click();
                    this.notification.showToast('Done!', 'Order PDF successfully downloaded', 'success');
                }
            } catch (e) {
                console.log(e);
                this.notification.showToast('Error', 'Something went wrong! Please try again.', 'error');
            } finally {
                this.generatingPdf = false;
            }
        }
    }

    async generateQrCode() {
        try {
            this.qrCode = await QRCode.toDataURL(this.order.paymentMethod.qrCodeValue);
        } catch (e) {
            console.error(e);
        }
    }

    getCardImageType() {
        if (this.order.cardType?.toLowerCase() === 'vi' || this.order.cardType?.toLowerCase() === 've' || this.order.cardType?.toLowerCase() === 'vd' || this.order.cardType.toLowerCase() === 'visa') {
            this.cardWidth = 42;
            return '/payment-methods/visa.svg';
        } else if (this.order.cardType?.toLowerCase() === 'mc' || this.order.cardType.toLowerCase() === 'mastercard') {
            return '/payment-methods/mastercard.svg';
        } else if (this.order.cardType?.toLowerCase() === 'am' || this.order.cardType.toLowerCase() === 'amex' || this.order.cardType.toLowerCase() === 'american express') {
            this.cardWidth = 42;
            return '/payment-methods/amex.svg';
        } else if (this.order.cardType?.toLowerCase() === 'dc' || this.order.cardType.toLowerCase() === 'diners' || this.order.cardType.toLowerCase() === 'diners club') {
            return '/payment-methods/diners.svg';
        } else if (this.order.cardType?.toLowerCase() === 'di' || this.order.cardType.toLowerCase() === 'discover') {
            this.cardWidth = 43;
            return '/payment-methods/discover.svg';
        } else if (this.order.cardType?.toLowerCase() === 'maestro') {
            this.cardWidth = 42;
            return '/payment-methods/maestro.svg';
        } else if (this.order.cardType?.toLowerCase() === 'jcb') {
            this.cardWidth = 42;
            return '/payment-methods/jcb.svg';
        } else if (this.order.cardType?.toLowerCase() === 'mada') {
            this.cardWidth = 42;
            return '/payment-methods/mada.svg';
        } else {
            this.cardWidth = 42;
            return '/payment-methods/generic.svg';
        }
    }

    async updateNavBarBalance() {
        if (this.order.balanceAmount > 0) {
            const navBarElement = document.getElementById('balance-tab');
            if (navBarElement && this.user) {
                navBarElement.childNodes[2].textContent = `Balance ${await this.currencyFormatValueConverter.toView(this.user?.balance)}`;
                if (navBarElement.childNodes?.[3]) {
                    navBarElement.removeChild(navBarElement.childNodes[3]);
                }
            }
        }
    }
}

