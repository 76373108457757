import './auth.scss';
import { ToastService } from 'services/toast-service';
import { CustomerService } from 'services/customer-service';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { WebsiteService } from 'services/website-service';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';

@autoinject()
export class Auth extends AuthenticationExtension {
    params;
    user;
    pages;
    homePageRoute;
    routeChangeSubscription;
    sizeChangeSubcription;
    state: string | string[] = 'sign-in';

    constructor(
        sessionService: SessionService,
        private router: Router,
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        toastService: ToastService,
        private websiteService: WebsiteService
    ) {
        super(toastService, sessionService);
    }

    async canActivate() {
        const refLink = await this._handleReferralAndReferrerLinks();
        await this.handleAuthRedirection('', null, null, refLink);
        return true;
    }

    async activate(params, routeConfig) {
        this.params = params;

        [this.user, this.pages] = await Promise.all([
            this.sessionService.refreshProfile(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';

        if (this.user && !params.accessToken && !params.fromEmail) {
            this.router.navigate('');
        } else if (this.user && params.fromEmail) {
            this.router.navigateToRoute('security');
        } else if (routeConfig.route.includes('childRoute')) {
            this.router.navigateToRoute('404');
        }
    }

    async attached() {
        this.handleEventSubscriptions();
    }

    detached() {
        this.routeChangeSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.routeChangeSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.state = this.router.currentInstruction?.config?.route;
        });
    }

    backToSignInPage() {
        this.eventAggregator.publish('pressed-back');
        this.router.navigate('sign-in');
    }

    private async _handleReferralAndReferrerLinks() {
        const urlParams = new URLSearchParams(window.location.search);
        const refLink = urlParams.get('ref');
        const platformLink = urlParams.get('gclid') ?? urlParams.get('msclkid');

        if (refLink) {
            await this.sessionService.saveReferralLink(refLink);
            urlParams.delete('ref');
        }

        const referrerLink = document.referrer;
        if (referrerLink && referrerLink !== '' && !refLink && !referrerLink.includes('divicasales') && !referrerLink.includes('localhost') && !referrerLink.includes('user.chicksgroup')) {
            await this.sessionService.saveReferrerLink(referrerLink);
        }

        if (platformLink) {
            let platform;
            urlParams.has('gclid') ? platform = 'gclid' : platform = 'msclkid';
            this.sessionService.setPlatformLinkCookie(platform, platformLink, 3);
        }

        return refLink;
    }
}
